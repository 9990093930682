<template>
    <div class="w-full">
        <span v-if="!show">
            {{ title }}

            <fa :icon="['fal','edit']" class="ml-2 fa-icon"  @click="focus" />

            <fa :icon="['fal','copy']" class="ml-2 fa-icon" />
        </span>

        <input v-else type="text" class="input" @blur="show = false" @change="show = false" ref="input" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            show: false,
        }
    },

    methods: {
        focus() {
            this.show = true;
            
            setTimeout(() => {
                this.$refs.input.focus();
            }, 50);
        }
    }
}
</script>

<style lang="scss" scoped>
.input {
    height: 21px !important;
}
</style>